<script setup>
    import { useRoute, useRouter } from 'vue-router/composables'
    import { onMounted, reactive, ref, toRefs } from 'vue'
     import {articleDetail} from '@/api/index'
import moment from 'moment'
    const route = useRoute()
    const query = route.query ||{}

    const state = reactive({
        detail:{}
    })

    const {detail} = toRefs(state)

    onMounted(() => {
        // 获取详情
        articleDetail(query).then(data=>{
            if(data){
                state.detail = {...data.data,timeDate:moment(data.createTime).format('YYYY年MM月DD日')}
            }

        })

    })
</script>

<template>
    <div class="wrap">
        <div class="wrap-box">
            <el-breadcrumb separator-class="el-icon-d-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/information' }">资讯</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>


            <h1 class="font-style-s tc">{{detail.title}}</h1>
            <p class="date-text font-style-s">{{detail.timeDate}}</p>
            <div class="content font-style-s" >
                <div v-html="detail.content"></div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.wrap{
    background: #ffffff;
    padding: 44px 0 200px;
    .wrap-box{
        width: 1420px;
        margin: auto;

        ::v-deep .el-breadcrumb{
            color:#070707;
            font-size:18px;
        }
    }

    h1{
        font-weight:700;
        color:#333333;
        font-size:56px;
        margin-top: 65px;
        margin-bottom: 22px;
    }
    .date-text{
        margin-bottom: 95px;
        font-weight:700;
        color:#434343;
        font-size:24px;
        text-align: center;
    }
    .content{
        color:#3b3b3b;
        font-size:24px;

        ::v-deep img{
            max-width:100%;
        }
    }
}
</style>
